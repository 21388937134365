import React from "react"

import LayoutBackgroundBurrito from "../components/layout-background-burrito"

const GalleryPage = () => {
    return (
        <LayoutBackgroundBurrito>
            <h1>Gallery</h1>
            <p>Display images</p>
        </LayoutBackgroundBurrito>
    )
}

export default GalleryPage